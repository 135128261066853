export class UserStateModel {
  constructor(userProfile, activePlans, acceptedTermsAndConditions, defaultPlanFreeMinutes, assignPhoneNumber, endPlansInfo) {
    this.userProfile = userProfile || {};
    this.activePlans = activePlans || [];
    this.acceptedTermsAndConditions = acceptedTermsAndConditions || [];
    this.defaultPlanFreeMinutes = defaultPlanFreeMinutes || [];
    this.assignPhoneNumber = assignPhoneNumber || new AssignPhoneNumberModel();
    this.endPlansInfo = endPlansInfo || [];
  }
}

export class AssignPhoneNumberModel {
  constructor(fetching, isAssignPhoneNumberStage, isVerificationStage, phoneNumber, prefix ) {
    this.fetching = fetching || false;
    this.isAssignPhoneNumberStage = isAssignPhoneNumberStage === undefined ? true : isAssignPhoneNumberStage;
    this.isVerificationStage = isVerificationStage === undefined ? false : isVerificationStage;
    this.phoneNumber = phoneNumber || '';
    this.prefix = prefix || '';

  }
}

export const EmailStatus = {
  NoRequired: 0,
  Verified: 1,
  RequiredVerification: 2,
  RequiredVerificationForOldUser: 3,
  RequiredVerificationForNewUser: 4,
};
