import React from 'react';
import PropTypes from 'prop-types';
import { Toolbar, Drawer, List, Typography } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/HomeTwoTone';
import WebIcon from '@material-ui/icons/Web';
import RidesIcon from '@material-ui/icons/MapTwoTone';
import PlansIcon from '@material-ui/icons/MoneyTwoTone';
import ProfileIcon from '@material-ui/icons/AccountCircleTwoTone';
import OperatorsIcon from '@material-ui/icons/PublicTwoTone';
import CardsIcon from '@material-ui/icons/CardGiftcardTwoTone';
import { useTranslation } from 'react-i18next';
import AppDrawerNavItem from './AppDrawerNavItem';
import routePaths from '../routePaths';
import logo from '../assets/roovee-logo.svg';
import BikeDefectButton from './bikeDefects/BikeDefectButton';

const AppDrawer = ({ open, docked, onRequestClose }) => {
  let constKey = 0;
  const getNextKey = () => constKey++;
  const [t] = useTranslation();


  const redirectToRooveeBlog = () => {
    window.location.href ='https://roovee.eu/blog-roovee/'
  }

  return (
    <Drawer
      open={open}
      onClose={onRequestClose}
      variant={docked ? 'permanent' : 'temporary'}
      classes={{paper: 'drawer'}}
    >
      <div style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
        <Toolbar style={{backgroundColor: '#FDE900'}}>
          <img src={logo} alt="ROOVEE" style={{width: '60%'}} />
        </Toolbar>
        <List style={{flexGrow: 1, }} className="drawerItem">
          <AppDrawerNavItem
            key={getNextKey()}
            title={t('dashboard.title')}
            to={routePaths.home}
            onClick={onRequestClose}
            icon={<DashboardIcon />}
          />
          <AppDrawerNavItem
            key={getNextKey()}
            title={t('rides.title')}
            to={routePaths.rides}
            onClick={onRequestClose}
            icon={<RidesIcon />}
          />
          <AppDrawerNavItem
            key={getNextKey()}
            title={t('plans.title')}
            to={routePaths.plans}
            onClick={onRequestClose}
            icon={<PlansIcon />}
          />
          <AppDrawerNavItem
            key={getNextKey()}
            title={t('cards.title')}
            to={routePaths.cards}
            onClick={onRequestClose}
            icon={<CardsIcon />}
          />
          <AppDrawerNavItem
            key={getNextKey()}
            title={t('operators.title')}
            to={routePaths.operators}
            onClick={onRequestClose}
            icon={<OperatorsIcon />}
          />
          <AppDrawerNavItem
            key={getNextKey()}
            title={t('userProfile.title')}
            to={routePaths.userProfile}
            onClick={onRequestClose}
            icon={<ProfileIcon />}
          />
          <BikeDefectButton
            key={getNextKey()}
          />
          <AppDrawerNavItem
            onClick={redirectToRooveeBlog}
            key={getNextKey()}
            title={t('dashboard.blog')}
            icon={<WebIcon />}
          />
        </List>
        <Typography variant="caption" style={{padding: 16}} className="drawerItem">
          <a href="https://roovee.eu" target="_blank" rel="noopener noreferrer">ROOVEE</a> &copy; 2023
        </Typography>
      </div>
    </Drawer>
  );
};

AppDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  docked: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
};

export default AppDrawer;
