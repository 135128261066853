import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Typography } from '@material-ui/core';
import { BikeParts } from '../../models/BikeParts';
import basket from '../../assets/bikeParts/basket.png';
import bell from '../../assets/bikeParts/bell.png';
import brakes from '../../assets/bikeParts/brakes.png';
import chain from '../../assets/bikeParts/chain.png';
import drive from '../../assets/bikeParts/drive.png';
import handlebar from '../../assets/bikeParts/handlebar.png';
import lights from '../../assets/bikeParts/lights.png';
import lock from '../../assets/bikeParts/lock.png';
import qr from '../../assets/bikeParts/qr.png';
import seat from '../../assets/bikeParts/seat.png';
import wheel from '../../assets/bikeParts/wheel.png';
import phoneHolder from '../../assets/bikeParts/phoneHolder.png';
import unusable from '../../assets/bikeParts/unusable.png';
import vandalism from '../../assets/bikeParts/vandalism.png';
import kickstand from '../../assets/bikeParts/kickstand.png';
import childSeat from '../../assets/bikeParts/childSeat.png';
import bikeBattery from '../../assets/bikeParts/bikeBattery.png';
import bikeScreen from '../../assets/bikeParts/bikeScreen.png';
import cargoBox from '../../assets/bikeParts/cargoBox.png';
import fender from '../../assets/bikeParts/fender.png';
import solar from '../../assets/bikeParts/solar.png';

import i18n from '../../i18n';

const styles = theme => ({
  button: {
    borderColor: '#fff',
    borderStyle: 'solid',
    borderWidth: 2,
    textAlign: 'center',
    cursor: 'pointer',
    borderRadius: 5,
  },
  buttonSelected: {
    borderColor: theme.palette.primary.light,
    backgroundColor: theme.palette.primary[50],
    borderStyle: 'solid',
    borderWidth: 2,
  },
  textSelected: {
    color: '#000',
  },
  image: {
    width: 60,
    height: 60,
  },
});

class BikePartButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: false,
    };

    this.handleClick = this.handleClick.bind(this);
  }

  getIcon() {
    switch (this.props.bikePart) {
      case BikeParts.basket: return basket;
      case BikeParts.bell: return bell;
      case BikeParts.brakes: return brakes;
      case BikeParts.chain: return chain;
      case BikeParts.drive: return drive;
      case BikeParts.handlebar: return handlebar;
      case BikeParts.lights: return lights;
      case BikeParts.lock: return lock;
      case BikeParts.qr: return qr;
      case BikeParts.seat: return seat;
      case BikeParts.wheel: return wheel;
      case BikeParts.phoneHolder: return phoneHolder;
      case BikeParts.unusable: return unusable;
      case BikeParts.vandalism: return vandalism;
      case BikeParts.kickstand: return kickstand;
      case BikeParts.childSeat: return childSeat;
      case BikeParts.bikeBattery: return bikeBattery;
      case BikeParts.bikeScreen: return bikeScreen;
      case BikeParts.cargoBox: return cargoBox;
      case BikeParts.fender: return fender;
      case BikeParts.solar: return solar;

      default: return null;
    }
  }

  handleClick() {
    const selected = !this.state.selected;
    this.setState({ selected });
    this.props.onSelect(this.props.bikePart, selected);
  }

  render() {
    const { bikePart, classes } = this.props;
    return (
      <div onClick={this.handleClick} className={classNames(classes.button, this.state.selected && classes.buttonSelected)}>
        <img src={this.getIcon()} className={classes.image} />
        <Typography variant="caption" align="center" className={this.state.selected ? classes.textSelected : ''} display="block">
          {i18n.t(`bikeDefects.parts.${bikePart.value}`)}
        </Typography>
      </div>
    );
  }
}

BikePartButton.propTypes = {
  classes: PropTypes.object.isRequired,
  bikePart: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default withStyles(styles)(BikePartButton);
