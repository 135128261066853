class MemoryStorage {
  constructor() {
    this.items = {};
  }

  getItem(key) {
    return this.items[key];
  }

  setItem(key, data) {
    this.items[key] = data;
  }

  removeItem(key) {
    return delete this.items[key];
  }
}

function checkIfLocalStorageIsAvailable(window) {
  try {
    window.localStorage.setItem('storageTest', 'storageTest');
    window.localStorage.removeItem('storageTest');
    return true;
  } catch (e) {
    return false;
  }
}

function localStorageProvider(win) {
  const storage = checkIfLocalStorageIsAvailable(win) ? win.localStorage : new MemoryStorage();

  function getItem(key) {
    return storage.getItem(key);
  }

  function setItem(key, data) {
    return storage.setItem(key, data);
  }

  function removeItem(key) {
    return storage.removeItem(key);
  }

  return {
    getItem,
    setItem,
    removeItem
  };
}

export default localStorageProvider(window);
