import initialState from './initialState';
import Immutable from 'seamless-immutable';
import { types } from '../actions/userActions';
import { types as termsTypes } from '../actions/termsAndConditionsActions';
import { types as plansTypes } from '../actions/plansActions';
import {AssignPhoneNumberModel, UserStateModel} from '../models/UserStateModel';

export default function userStateReducer(state = Immutable(initialState.userState), action) {
   switch (action.type) {
     case types.GET_USER_STATE_SUCCESS:
      return state.merge(new UserStateModel(
        action.userState.profile,
        action.userState.activePlans,
        action.userState.acceptedTermsAndConditions,
        action.userState.defaultPlanFreeMinutes,
        state.assignPhoneNumber,
        action.userState.endPlansInfo));
    case termsTypes.ACCEPT_TERMS_SUCCESS: {
      const acceptedTermsAndConditions = [{ tenant: action.tenant, isLatestVersionAccepted: true }, ...state.acceptedTermsAndConditions.filter(t => t.tenant !== action.tenant)];
      return state.merge({ acceptedTermsAndConditions });
    }
    case types.UPDATE_USER_PROFILE_SUCCESS: {
      const newProfile = Object.assign({}, state.userProfile);
      newProfile.firstName = action.userDetails.firstName;
      newProfile.lastName = action.userDetails.lastName;
      newProfile.email = action.userDetails.email;
      return state.merge(new UserStateModel(newProfile, state.activePlans, state.acceptedTermsAndConditions, state.defaultPlanFreeMinutes, state.assignPhoneNumber, state.endPlansInfo));
    }
    case types.UPDATE_USER_PREFERRED_LANGUAGE_SUCCESS: {
      const newProfile = Object.assign({}, state.userProfile);
      newProfile.preferredLanguage = action.languageCode;
      return state.merge(new UserStateModel(newProfile, state.activePlans, state.acceptedTermsAndConditions, state.assignPhoneNumber, state.endPlansInfo));
    }
     case types.RESET_ASSIGN_PHONE_NUMBER: {
       return state.merge(new UserStateModel(state.userProfile, state.activePlans, state.acceptedTermsAndConditions, state.defaultPlanFreeMinutes, new AssignPhoneNumberModel(), state.endPlansInfo));
     }
    case types.ASSIGN_PHONE_NUMBER_REQUEST: {
      const assignPhoneNumberModel = new AssignPhoneNumberModel(
        true,
        true,
        false,
        action.phoneNumber,
        action.phoneNumberPrefix
      );
      return state.merge(new UserStateModel(state.userProfile, state.activePlans, state.acceptedTermsAndConditions, state.defaultPlanFreeMinutes, assignPhoneNumberModel, state.endPlansInfo));
    }
    case types.ASSIGN_PHONE_NUMBER_SUCCESS: {
       const assignPhoneNumberModel = new AssignPhoneNumberModel(
         false,
         false,
         true,
         state.assignPhoneNumber.phoneNumber,
         state.assignPhoneNumber.prefix
       );
       return state.merge(new UserStateModel(state.userProfile, state.activePlans, state.acceptedTermsAndConditions, state.defaultPlanFreeMinutes, assignPhoneNumberModel, state.endPlansInfo));
     }
    case types.ASSIGN_PHONE_NUMBER_FAILURE: {
       const assignPhoneNumberModel = new AssignPhoneNumberModel(
         false,
         true,
         false,
         state.assignPhoneNumber.phoneNumber,
         state.assignPhoneNumber.prefix
       );
       return state.merge(new UserStateModel(state.userProfile, state.activePlans, state.acceptedTermsAndConditions, state.defaultPlanFreeMinutes, assignPhoneNumberModel, state.endPlansInfo));
     }
     case types.ASSIGN_PHONE_NUMBER_FINISHED_REQUEST: {
       const assignPhoneNumberModel = new AssignPhoneNumberModel(
         true,
         false,
         true,
         state.assignPhoneNumber.phoneNumber,
         state.assignPhoneNumber.prefix
       );
       return state.merge(new UserStateModel(state.userProfile, state.activePlans, state.acceptedTermsAndConditions, state.defaultPlanFreeMinutes, assignPhoneNumberModel, state.endPlansInfo));
     }
     case types.ASSIGN_PHONE_NUMBER_FINISHED_SUCCESS: {
       const assignPhoneNumberModel = new AssignPhoneNumberModel(
         false,
         false,
         false,
         '',
         ''
       );
       return state.merge(new UserStateModel(state.userProfile, state.activePlans, state.acceptedTermsAndConditions, state.defaultPlanFreeMinutes, assignPhoneNumberModel, state.endPlansInfo));
     }
     case plansTypes.CONFIRM_END_PLAN_INFO_SUCCESS: {
       const endPlansInfo = state.endPlansInfo.filter(x => x.id !== action.id)
       return state.merge(new UserStateModel(state.userProfile, state.activePlans, state.acceptedTermsAndConditions, state.defaultPlanFreeMinutes, state.assignPhoneNumber, endPlansInfo));
     }
     case types.ASSIGN_PHONE_NUMBER_FINISHED_FAILURE: {
       const assignPhoneNumberModel = new AssignPhoneNumberModel(
         false,
         false,
         true,
         state.assignPhoneNumber.phoneNumber,
         state.assignPhoneNumber.prefix
       );
       return state.merge(new UserStateModel(state.userProfile, state.activePlans, state.acceptedTermsAndConditions, state.defaultPlanFreeMinutes, assignPhoneNumberModel, state.endPlansInfo));
     }
    default:
      return state;
  }
}
