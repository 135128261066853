import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { withStyles } from '@material-ui/core/styles';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import MyProfileMenu from './appBar/MyProfileMenu';
import AppDrawer from './AppDrawer';
import './AppStyles.scss';
import AcceptTermsAndConditionsDialog from './termsAndConditions/AcceptTermsAndConditionsDialog';
import ContactIconButton from './contact/ContactIconButton';
import routePaths from "../routePaths";
import NoVerifyUserEmailDialog from "./dashboard/NoVerifyUserEmailDialog";
import ConfirmAutomaticWithdrawalInfoModal from "./dashboard/ConfirmAutomaticWithdrawalInfoModal"
import ConfirmEndPlanDialog from "./dashboard/ConfirmEndPlanDialog";

const styles = theme => ({
  appBar: {
    transition: theme.transitions.create('width'),
  },
  [theme.breakpoints.up('lg')]: {
    appBarShift: {
      width: 'calc(100% - 250px)',
    },
    appContainer: {
      marginLeft: 250
    },
    navIconHide: {
      display: 'none',
    },
  },
  title: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden !important',
  },
  [theme.breakpoints.down('sm')]: {
    userName: {
      display: 'none',
    },
  },
});

class AppFrame extends Component {
  constructor(props) {
    super(props);

    this.state = {
      drawerOpen: false,
      title: '',
    };

    this.handleDrawerClose = this.handleDrawerClose.bind(this);
    this.handleDrawerToggle = this.handleDrawerToggle.bind(this);

    new MutationObserver(mutations => this.setState({ title: mutations.length > 0 && mutations[0].target ? mutations[0].target.innerText : '' }))
      .observe(document.querySelector('title'), { subtree: true, characterData: true, childList: true });
  }

  handleDrawerClose() {
    this.setState({ drawerOpen: false });
  }

  handleDrawerToggle() {
    this.setState({ drawerOpen: !this.state.drawerOpen });
  }

  isNoneFrameContent(){
    return window.location.pathname === routePaths.verifyEmail || window.location.pathname === routePaths.oauth2Success
      || window.location.pathname === routePaths.oauth2Failure;
  }

  render() {
    const classes = this.props.classes;
    const drawerDocked = isWidthUp('lg', this.props.width);
    const appBarClassName = `${classes.appBar} ${classes.appBarShift}`;
    const navIconClassName = `${classes.navIconHide}`;
    if(this.isNoneFrameContent()){
      return (
        <div className="root">
          {this.props.children}
        </div>)
    }
    return (
      <div className="root">
        <AppBar className={appBarClassName} position="fixed" elevation={0}>
          <Toolbar>
            <IconButton color="default" aria-label="Menu" onClick={this.handleDrawerToggle} className={navIconClassName}>
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" className={classes.title}>{document.title}</Typography>
            <div className="grow" />
            <Typography color="inherit" className={classes.userName}>{this.props.userProfile.firstName} {this.props.userProfile.lastName}</Typography>
            <ContactIconButton />
            <MyProfileMenu />
          </Toolbar>
        </AppBar>
        <AppDrawer
          docked={drawerDocked}
          onRequestClose={this.handleDrawerClose}
          open={drawerDocked || this.state.drawerOpen}
        />
        <div className={classes.appContainer}>
          {this.props.children}
        </div>
        <AcceptTermsAndConditionsDialog />
        <NoVerifyUserEmailDialog />
        <ConfirmAutomaticWithdrawalInfoModal />
        <ConfirmEndPlanDialog />
      </div>
    );
  }
}

AppFrame.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  width: PropTypes.string,
  userProfile: PropTypes.object.isRequired,
};

export default withStyles(styles, { name: 'AppFrame' })(withWidth()(AppFrame));
