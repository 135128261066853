import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import {Button, Dialog, Typography} from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import {useDispatch, useSelector} from "react-redux";
import {actions} from '../../actions/plansActions'

const ConfirmEndPlanDialog = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const endPlans = useSelector(x => x.userState.endPlansInfo);
  const [submitting, setSubmitting] = useState(false);

  const handleOnConfirm = async (id) => {
    setSubmitting(true);
    await dispatch(actions.confirmEndPlanInfo(id))
    setSubmitting(false);
  }

  if (endPlans.length === 0) {
    return null;
  }

  return (
    <Dialog open={true}>
      <DialogTitle>{t(`confirmEndPlanDialog.warning`)}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography variant="body1" style={{textAlign: "justify"}}>
            {t(`confirmEndPlanDialog.information`, {planName: endPlans[0].planName})}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" disabled={submitting} onClick={async () => {await handleOnConfirm(endPlans[0].id)}}>
          {t(`confirmEndPlanDialog.confirm`)}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmEndPlanDialog;
